<template>
    <div class="login">
        <div class="login__block register__block">
            <div class="login__block__header">
                <i class="zmdi zmdi-account-circle"></i>
                Let's get you started. Provide details about your church.
            </div>

            <div class="login__block__body mt-4">
                <form @submit.prevent="register">                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-account">Your Church or Organization Name</label>
                                <input v-model="account.name" type="text" class="form-control" id="user-account" required tabindex="1">
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-branch">Branch Name <em>(optional)</em></label>
                                <input v-model="account.branch" type="text" class="form-control" id="user-branch" tabindex="2">
                                <i class="form-group__bar"></i>
                            </div> 
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-fname">First Name</label>
                                <input v-model="account.firstName" type="text" class="form-control" id="user-fname" required tabindex="3">
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-lname">Last Name</label>
                                <input v-model="account.lastName" type="text" class="form-control" id="user-lname" required tabindex="4">
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-email">Email Address</label>
                                <input v-model="account.email" type="email" class="form-control" :class="isValidEmail == false ? 'is-invalid' : ''" @blur="checkEmail" id="user-email" required tabindex="5">
                                <div class="invalid-feedback" v-if="isValidEmail == false">Please provide a valid email address</div>
                                <i class="form-group__feedback zmdi zmdi-close-circle" v-if="isValidEmail == false"></i>
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-phone">Telephone</label>
                                <vue-tel-input mode="international" :defaultCountry="'GH'" :enabledFlags="true" :enabledCountryCode="false" :disabledFormatting="true" wrapperClasses="telinput" inputId="user-phone" placeholder="" v-model="account.telephone" :class="isValidTelephone == false ? 'is-invalid' : ''" @blur="checkTelephone" @onInput="onInput" required tabindex="6"></vue-tel-input>
                                <div class="invalid-feedback" v-if="isValidTelephone == false">Please provide a valid input</div>
                                <i class="form-group__feedback zmdi zmdi-close-circle" v-if="isValidTelephone == false"></i>
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-password">Password</label>
                                <input v-model="account.password" type="password" class="form-control" id="user-password" required  tabindex="7">
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user-rpassword">Repeat Password</label>
                                <input v-model="account.repeatPassword" type="password" class="form-control" id="user-rpassword" required tabindex="8">
                                <i class="form-group__bar"></i>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input v-model="account.acceptTerms" type="checkbox" id="account-terms" tabindex="10">
                                    <label class="checkbox__label" for="account-terms">
                                        Accept the license <router-link to="/legal/terms">agreement</router-link>
                                    </label>
                                </div>                        
                            </div>
                        </div>
                        <div class="col-md-7">        
                            <button type="submit" class="btn btn-success btn--icon-text">
                                <i class="zmdi zmdi-long-arrow-right"></i>
                                REGISTER
                            </button>
                        </div>
                        <div class="col-md-5">
                            <button type="button" class="btn btn-link btn--icon-text" @click="goToLogin">
                                <i class="zmdi zmdi-sign-in"></i>
                                Already registered? LOGIN
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {

                account: {
                    name: "",
                    branch: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    telephone: "",
                    password: "",
                    repeatPassword: "",
                    acceptTerms: false,
                },

                checkPassed: true,

                isValidEmail: true,

                isValidTelephone: true,

            }
        },
        methods: {
            register(){
                this.$store.dispatch('register', this.account).then(response => {
                    if(response.data.code === 1000){
                        this.$router.push('/verify')
                    }
                })
            },

            goToLogin(){
                this.$router.push('/login')
            },

            checkEmail(){
                this.$http.get("/accounts/checkemail?email=" + this.account.email).then(response => {
                    if(response.data.code === 1022){
                        this.isValidEmail = true
                        this.checkPassed = true
                    } else {
                        this.isValidEmail = false
                        this.checkPassed = false
                    }
                })
            },

            checkTelephone(){
                this.$http.get("/accounts/checktelephone?telephone=" + this.account.telephone).then(response => {
                    if(response.data.code === 1024){
                        this.isValidTelephone = true
                        this.checkPassed = true
                    } else {
                        this.isValidTelephone = false
                        this.checkPassed = false
                    }
                })
            },

            onInput({ number }) {
                this.account.telephone = number.e164
            },

        }
    }
</script>